import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";

function App() {
  const [formData, setFormData] = useState({
    empCode: '',
    title: 'Select Title',
    empName: '',
    email: '',
    mobile: '',
    bu: 'Select Business Unit',
    workLoc: '',
    grade: '',
    dept: '',
    trReqno: '',
    depLoc: '',
    arrivalLoc: '',
    depDate: '',
    arrivalDate: '',
    stayLocality: '',
  });

  const flattenedData = [
    { name: "3XPER-CORP", id: 2723 },
    { name: "3XPER-MFG", id: 2723 },
    { name: "3XPER-R&D", id: 2723 },
    { name: "CGSEMI-ASSEMBLY", id: 3117 },
    { name: "CGSEMI-COMMON", id: 3117 },
    { name: "CGSEMI-TEST", id: 3117 },
    { name: "MFPD COMMON", id: 803 },
    { name: "MFPD-AUTO", id: 803 },
    { name: "MFPD-AUTOCHAINS", id: 803 },
    { name: "MFPD-FINEBLANKING", id: 803 },
    { name: "MFPD-RAILWAYS", id: 803 },
    { name: "TICI - EXPORTS & ADJACENCIES", id: 803 },
    { name: "TICI-DOMESTIC", id: 803 },
    { name: "TICO-BU-PRIMARY", id: 803 },
    { name: "TIEB", id: 803 },
    { name: "TIID-IC", id: 803 },
    { name: "TIOB -TBW", id: 803 },
    { name: "TIOB-TIMB", id: 803 },
    { name: "TIOB-TIOS", id: 803 },
    { name: "TIOB-TMT", id: 803 },
    { name: "TPI CHANNEL SALES", id: 803 },
    { name: "TPI EXPORTS", id: 803 },
    { name: "TPI LARGE DIA", id: 803 },
    { name: "TPI NORTH", id: 803 },
    { name: "TPI SOUTH", id: 803 },
    { name: "TPI WEST", id: 803 },
    { name: "TPI-CRSS-AVADI", id: 803 },
    { name: "TPI-CRSS-NASIK", id: 803 },
    { name: "CETPL-4W", id: 2739 },
    { name: "CETPL-COMPANY PRIMARY", id: 2739 },
    { name: "IPLT", id: 2739 },
    { name: "MEPL-NOIDA", id: 2739 },
    { name: "TIVOLT", id: 2739 },
    { name: "ICM E-TRAC", id: 2739 },
    { name: "TICM-3W", id: 2739 },
    { name: "TICM-CORP", id: 2739 },
    { name: "SGL-CMN", id: 909 },
    { name: "SGL-GGB", id: 909 },
    { name: "SGL-SFW", id: 909 },
  ];
  let title = [{ name: "Mr" }, { name: "Mrs" }];

 
  const [errors, setErrors] = useState({});

  // Update arrivalDate when depDate changes
  useEffect(() => {
    if (formData.depDate) {
      const arrival = new Date(formData.depDate);
      arrival.setDate(arrival.getDate() + 1); // Add 1 day to depDate to get arrivalDate
      setFormData((prevState) => ({
        ...prevState,
        arrivalDate: arrival.toISOString().split("T")[0], // Set arrivalDate in form state
      }));
    }
  }, [formData.depDate]); // Only run this effect when depDate changes

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  /*const validateForm = () => {
    const newErrors = {};
    if (!formData.empCode) newErrors.empCode = "Employee Code is required";
    if (!formData.title || formData.title === "Select title") {
      newErrors.title = "Title is required";
    } else if (formData.title !== "Mr" && formData.title !== "Mrs") {
      newErrors.title = "Title must be 'Mr' or 'Mrs'";
    }

    if (!formData.empName) newErrors.empName = "Employee Name is required";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Valid email is required";
    }
    if (!formData.mobile) newErrors.mobile = "Mobile number is required";
    if (!formData.bu || formData.bu === "Select")
      newErrors.bu = "Business Unit is required";
    if (!formData.workLoc) newErrors.workLoc = "Work Location is required";
    if (!formData.grade) newErrors.grade = "Grade is required";
    if (!formData.dept) newErrors.dept = "Department is required";
    if (!formData.tripDetails)
      newErrors.tripDetails = "Trip Details are required";
    if (!formData.trReqno)
      newErrors.trReqno = "Travel Request Number is required";
    if (!formData.depLoc) newErrors.depLoc = "Departure Location is required";
    if (!formData.arrivalLoc)
      newErrors.arrivalLoc = "Arrival Location is required";
    if (!formData.depDate) newErrors.depDate = "Departure Date is required";
    if (!formData.arrivalDate)
      newErrors.arrivalDate = "Arrival Date is required";
    if (!formData.stayLocality)
      newErrors.stayLocality = "Stay Locality is required";

    // Check if arrival date is after departure date or vice versa
    if (formData.depDate && formData.arrivalDate) {
      const depDateObj = new Date(formData.depDate);
      const arrivalDateObj = new Date(formData.arrivalDate);

      if (depDateObj > arrivalDateObj) {
        newErrors.dateError = "Departure date cannot be after arrival date.";
      } else if (arrivalDateObj < depDateObj) {
        newErrors.dateError = "Arrival date cannot be before departure date.";
      }
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };
*/

const validateForm = () => {
  const newErrors = {};

  // Validate form fields
  if (!formData.empCode) newErrors.empCode = "Employee Code is required";
  if (!formData.title || formData.title === "Select Title") {
    newErrors.title = "Title is required";
  } else if (formData.title !== "Mr" && formData.title !== "Mrs") {
    newErrors.title = "Title must be 'Mr' or 'Mrs'";
  }

  if (!formData.empName) newErrors.empName = "Employee Name is required";
  if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
    newErrors.email = "Valid email is required";
  }
  if (!formData.mobile) newErrors.mobile = "Mobile number is required";
  if (!formData.bu || formData.bu === "Select Business Unit") newErrors.bu = "Business Unit is required";
  if (!formData.workLoc) newErrors.workLoc = "Work Location is required";
  if (!formData.grade) newErrors.grade = "Grade is required";
  if (!formData.dept) newErrors.dept = "Department is required";
   if (!formData.trReqno) newErrors.trReqno = "Travel Request Number is required";
  if (!formData.depLoc) newErrors.depLoc = "Departure Location is required";
  if (!formData.arrivalLoc) newErrors.arrivalLoc = "Arrival Location is required";
  if (!formData.depDate) newErrors.depDate = "Departure Date is required";
  if (!formData.arrivalDate) newErrors.arrivalDate = "Arrival Date is required";
  if (!formData.stayLocality) newErrors.stayLocality = "Stay Locality is required";

  // Date validation (departure must be before arrival)
  // if (formData.depDate && formData.arrivalDate) {
  //   const depDateObj = new Date(formData.depDate);
  //   const arrivalDateObj = new Date(formData.arrivalDate);

  //   if (depDateObj >= arrivalDateObj) {
  //     newErrors.dateError = "Departure date cannot be on or after arrival date.";
  //   }
  // }

  // Set the errors
  setErrors(newErrors);

  // Return true if there are no errors
  
  return Object.keys(newErrors).length === 0;
};

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();


    if (validateForm()) {
      // Process the form (e.g., send to backend or log)
 

   
      // Clear form or show success message if needed
    
      let business_unit = formData?.bu.split(" ");
      let lastValue = business_unit[business_unit.length - 1];
      let nameParts = formData?.empName.split(" ");
      // Extract the first part (San)
      let firstName = nameParts[0];

      // Join the rest of the parts as the last name
      let lastName = nameParts.slice(1).join(" ");

      let inputdata = {
        booker_name: formData?.empName,
        booker_email: formData?.email,
        client_request_no: formData?.trReqno,
        ClientId: lastValue,
        bookingdetails: [
          {
            checkin: formData?.arrivalDate,
            checkout: formData?.depDate,
            expected_checkin_time: "02:00 PM",
            expected_checkout_time: "11:59 PM",
            locality: formData?.stayLocality,
            city: formData?.arrivalLoc,
            prefered_hotel: "",
            remarks: "",
            guest_details: [
              {
                emp_code: formData?.empCode,
                title: formData?.title,
                first_name: firstName,
                last_name: lastName,
                mobile_no: formData?.mobile,
                email: formData?.email,
                designation: "",
                grade_level: formData?.grade,
                column1: business_unit[0] || formData?.bu,
                column2: formData?.dept,
                column3: formData?.workLoc,
                column4: formData?.trReqno,
                column5: "",
                column6: "",
                column7: "",
                column8: "",
                column9: "",
                column10: "",
              },
            ],
          },
        ],
      };


      axios
        .post(
          "https://travelrrequestdevelopers.hummingbirdindia.com/api/abbott/request",
          inputdata
        ) // Replace with your API URL
        .then((response) => {
          
          alert("Form submitted successfully");
          window.location.reload();
        })
        .catch((err) => {
          // setError('Error fetching data');
          // setLoading(false);
        });
      setFormData({
        empCode: "",
        title: 'Select Title',
        empName: "",
        email: "",
        mobile: "",
        bu: 'Select Business Unit',
        workLoc: "",
        grade: "",
        dept: "",
        trReqno: "",
        depLoc: "",
        arrivalLoc: "",
        depDate: "",
        arrivalDate: "",
        stayLocality: "",
      });
     }
  };

  // Clear form data
  const handleClear = () => {
    setFormData({
      empCode: "",
      title: 'Select Title',
      empName: "",
      email: "",
      mobile: "",
      bu: 'Select Business Unit',
      workLoc: "",
      grade: "",
      dept: "",
      trReqno: "",
      depLoc: "",
      arrivalLoc: "",
      depDate: "",
      arrivalDate: "",
      stayLocality: "",
    });
    window.location.reload();
    // Clear errors as well
    setErrors({});
  };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   if (name === 'depYear' || name === 'depMonth' || name === 'depDay') {
  //     setFormData((prevData) => {
  //       const newDate = new Date(
  //         prevData.depYear || new Date().getFullYear(),
  //         prevData.depMonth ? prevData.depMonth - 1 : 0, // Months are 0-indexed
  //         prevData.depDay || 1
  //       );

  //       if (name === 'depYear') {
  //         newDate.setFullYear(value);
  //       } else if (name === 'depMonth') {
  //         newDate.setMonth(value - 1); // Months are 0-indexed
  //       } else if (name === 'depDay') {
  //         newDate.setDate(value);
  //       }

  //       return {
  //         ...prevData,
  //         depYear: newDate.getFullYear(),
  //         depMonth: newDate.getMonth() + 1,
  //         depDay: newDate.getDate(),
  //         depDate: newDate.toISOString().split('T')[0], // Format as YYYY-MM-DD
  //       };
  //     });
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }
  // };

  const getMinDateForDepDate = () => {
    if (formData.depDate) {
      const arrival = new Date(formData.depDate);
      arrival.setDate(arrival.getDate() + 1); // Adding 1 day to arrivalDate
      // Update the arrivalDate in formData state

      return arrival.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
    }
    return ""; // Return empty if arrivalDate is not selected
  };

  return (
    <div
  className="App"
  style={{
    background: "#f3f3f3",
    boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.3)",
  }}
>
 

      <h1>TII Request Form</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label>Employee Code: </label>
            <input
              type="text"
              name="empCode"
              value={formData.empCode}
              onChange={handleChange}
            />
            {errors.empCode && <span className="error">{errors.empCode}</span>}
          </div>

          <div className="form-group">
            <label>Title: </label>
            {/* <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
          /> */}
            <select
              onChange={handleChange}
              name="title"
             style={{ width: "104%", height: "33px",border:"1px solid #ddd" }}
            >
              <option value={"Select Title"}>{"Select Title"}</option>
              {title.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
            {errors.title && <span className="error">{errors.title}</span>}
          </div>

          <div className="form-group">
            <label>Employee Name: </label>
            <input
              type="text"
              name="empName"
              value={formData.empName}
              onChange={handleChange}
            />
            {errors.empName && <span className="error">{errors.empName}</span>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Email: </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          <div className="form-group">
            <label>Mobile: </label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              maxLength={10}
              pattern="\d{10}"
              placeholder="Enter 10-digit mobile number"
            />

            {errors.mobile && <span className="error">{errors.mobile}</span>}
          </div>

          <div className="form-group">
            <label>Business Unit: </label>
            {/* <input
            type="text"
            name="bu"
            value={formData.bu}
            onChange={handleChange}
          /> */}
            <select
              onChange={handleChange}
              name="bu"
             style={{ width: "104%", height: "33px",border:"1px solid #ddd" }}
            >
              <option value={"Select Business Unit"}>{"Select Business Unit"}</option>
              {flattenedData.map((item, index) => (
                <option key={index} value={item.name + " " + item.id}>
                  {item.name}
                </option>
              ))}
            </select>
            {errors.bu && <span className="error">{errors.bu}</span>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Work Location: </label>
            <input
              type="text"
              name="workLoc"
              value={formData.workLoc}
              onChange={handleChange}
            />
            {errors.workLoc && <span className="error">{errors.workLoc}</span>}
          </div>

          <div className="form-group">
            <label>Grade: </label>
            <input
              type="text"
              name="grade"
              value={formData.grade}
              onChange={handleChange}
            />
            {errors.grade && <span className="error">{errors.grade}</span>}
          </div>

          <div className="form-group">
            <label>Department: </label>
            <input
              type="text"
              name="dept"
              value={formData.dept}
              onChange={handleChange}
            />
            {errors.dept && <span className="error">{errors.dept}</span>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Stay Locality: </label>
            <input
              type="text"
              name="stayLocality"
              value={formData.stayLocality}
              onChange={handleChange}
            />
            {errors.stayLocality && (
              <span className="error">{errors.stayLocality}</span>
            )}
          </div>
          <div className="form-group">
            <label>Travel Request Number: </label>
            <input
              type="text"
              name="trReqno"
              value={formData.trReqno}
              onChange={handleChange}
            />
            {errors.trReqno && <span className="error">{errors.trReqno}</span>}
          </div>

          <div className="form-group">
            <label>Departure Location: </label>
            <input
              type="text"
              name="depLoc"
              value={formData.depLoc}
              onChange={handleChange}
            />
            {errors.depLoc && <span className="error">{errors.depLoc}</span>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Arrival Location: </label>
            <input
              type="text"
              name="arrivalLoc"
              value={formData.arrivalLoc}
              onChange={handleChange}
            />
            {errors.arrivalLoc && (
              <span className="error">{errors.arrivalLoc}</span>
            )}
          </div>

          <div className="form-group">
            <label>Check-In Date: </label>

            <input
              type="date"
              name="depDate"
              value={formData.depDate}
              onChange={handleChange}
              min={new Date().toISOString().split("T")[0]}

              // min={new Date().toISOString().split('T')[0]}
            />
            {errors.depDate && <span className="error">{errors.depDate}</span>}
          </div>

          <div className="form-group">
            <label>Check-Out Date: </label>

            <input
              type="date"
              name="arrivalDate"
              value={formData.arrivalDate}
              onChange={handleChange}
              min={
                getMinDateForDepDate() || new Date().toISOString().split("T")[0]
              }
            />
            {errors.arrivalDate && (
              <span className="error">{errors.arrivalDate}</span>
            )}
          </div>
        </div>

        <div style={{textAlign:"center"}}>
          <button type="submit">Submit</button>
          <button type="button" onClick={handleClear}>
            Clear
          </button>
        </div>
      </form>
    </div>
  );
}

export default App;
